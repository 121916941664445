import useSearchString from "../../../utils/useSearchString";
import { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import getAsset from "../../../api/utils/getAsset";
import getShop from "./../../../api/utils/getShop";
import trailer from "../../../images/trailer.svg";
import marker from "../../../images/marker.svg";
import calendar from "../../../images/calendar.svg";
import logo from "../../../images/logo.png";
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Moment from "moment";
import SmartForm from "./../../Form/SmartForm";
import InputFieldWithValidation from "../../Form/elements/InputFieldWithValidation";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import { getPayerauthenticate } from "../../../api/utils/getPayerauthenticate";
import setOrderStatus from "../../../api/utils/setOrderStatus";
import {
    ALLOWED_EMAIL_DOMAINS,
    INCORRECT_EMAIL_DOMAIN_REDIRECT_URL,
} from "../../../config";

const AssetReservation = () => {
    const { asset, pickUpDate, returnDate, id } = useSearchString();
    const [assetInfo, setAssetInfo] = useState();
    const [shop, setShop] = useState([]);
    const [user, setUser] = useState({});
    const [pOSId, setPOSId] = useState("");
    const theme = createTheme();
    const history = useHistory();
    Moment.locale("en");
    let isMounted = true;

    useEffect(() => {
        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        if (asset) {
            fetchAsset();
        }
    }, [asset]);

    const fetchAsset = async () => {
        const resultHandler = (data) => {
            if (isMounted) {
                if (data && data.status === "accept") {
                    setAssetInfo(data.asset);
                }
            }
        };
        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
        };
        getAsset({ id: asset }, errorHandler).then((data) => {
            return resultHandler(data);
        });
    };

    useEffect(() => {
        setPOSId(assetInfo?.properties?.point_of_sale);
        if (assetInfo?.properties) {
            if (assetInfo?.properties?.pos) {
                fetchShop();
            }
        } else {
            // pass
        }
    }, [assetInfo]);

    const fetchShop = async () => {
        const resultHandler = (data) => {
            if (isMounted) {
                if (data && data.status === "accept") {
                    setShop(data.shop);
                } else {
                    setShop({});
                }
            }
        };
        const errorHandler = (error) => {
            NotificationManager.error(error.description, "Error", 4000);
            setShop({});
        };

        getShop({ id: assetInfo?.properties?.pos }, errorHandler).then(
            (data) => {
                return resultHandler(data);
            }
        );
    };

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: "center",
        color: "#000",
    }));

    let onSubmit = (datas) => {
        const domain = datas?.Email?.split("@")[1];

        const allowedEmailDomains = [...ALLOWED_EMAIL_DOMAINS];

        if (process.env.REACT_APP_TEST && domain === "gmail.com")
            allowedEmailDomains.push("gmail.com");

        if (allowedEmailDomains.includes(domain)) {
            const resultHandler = (data) => {
                if (isMounted) {
                    if (data && data.status === "accept") {
                        window.location = data?.auth_link;
                    } else {
                        NotificationManager.error("Error");
                    }
                }
            };
            getPayerauthenticate(assetInfo?.merchant, id, shop._id, datas)
                .then((data) => {
                    return resultHandler(data);
                })
                .catch(console.error);
        } else {
            const resultHandler = () => {
                NotificationManager.success(`Status changed`, "Success", 4000);

                window.open(INCORRECT_EMAIL_DOMAIN_REDIRECT_URL);
            };
            const errorHandler = (error) => {
                NotificationManager.error(error.description, "Error", 4000);
            };
            setOrderStatus(id, "email_violation", errorHandler).then((data) => {
                return resultHandler(data);
            });
        }
    };

    const handlInput = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };
    const handleCheck = (e) => {
        setUser({ ...user, [e.target.name]: e.target?.checked });
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <AppBar position="static" sx={{ background: "rgb(0 112 173)" }}>
                <Toolbar>
                    <Container maxWidth="xl">
                        <Typography variant="h6" href="/" component="a">
                            <img src={logo} alt="logo" />
                        </Typography>
                    </Container>
                </Toolbar>
            </AppBar>
            <main className="page-content">
                <Box sx={{ pt: 8, pb: 8 }}>
                    <Container component="main">
                        <Grid
                            container
                            spacing={{ xs: 2, md: 5, lg: 5 }}
                            className="contact-us"
                        >
                            <Grid item xs={12} sm={6} md={4}>
                                <Item>
                                    <Card>
                                        <CardHeader
                                            title="Ajankohta"
                                            className="text-white"
                                        ></CardHeader>
                                        <CardContent>
                                            <div className="mb-3">
                                                <img
                                                    src={calendar}
                                                    className="contact-icon"
                                                    alt="calendar"
                                                />
                                            </div>
                                            <Typography
                                                variant="body1"
                                                component="div"
                                                className="fw-bold mb-1"
                                            >
                                                {`Nouto ${
                                                    Moment(pickUpDate).format(
                                                        "Do MMM YY"
                                                    ) || "-"
                                                }`}
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                component="div"
                                                className="fw-bold"
                                            >
                                                {`Palautus ${
                                                    Moment(returnDate).format(
                                                        "Do MMM YY"
                                                    ) || "-"
                                                }`}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Item>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Item>
                                    <Card>
                                        <CardHeader
                                            title="Sijainti"
                                            className="text-white"
                                        ></CardHeader>
                                        <CardContent>
                                            <div className="mb-3">
                                                <img
                                                    src={marker}
                                                    className="contact-icon"
                                                    alt="marker"
                                                />
                                            </div>
                                            <Typography
                                                variant="body1"
                                                component="div"
                                                className="fw-bold mb-1"
                                            >
                                                {assetInfo?.name || "-"}
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                component="div"
                                                className="fw-bold"
                                            >
                                                {shop?.settings?.str_address ||
                                                    "Sijaintitetoja ei ole saatavilla"}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Item>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Item>
                                    <Card>
                                        <CardHeader
                                            title="Vaunun tiedot"
                                            className="text-white"
                                        ></CardHeader>
                                        <CardContent>
                                            <div className="mb-3">
                                                <img
                                                    src={trailer}
                                                    className="contact-icon"
                                                    alt="Trailer"
                                                />
                                            </div>
                                            <Typography
                                                variant="body1"
                                                component="div"
                                                className="fw-bold mb-1"
                                            >
                                                {assetInfo?.name}
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                component="div"
                                                className="fw-bold mb-1"
                                            >
                                                {`Sisämitat: ${
                                                    assetInfo?.properties[
                                                        "trailer_interior_size"
                                                    ] || "-"
                                                }`}
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                component="div"
                                                className="fw-bold mb-1"
                                            >
                                                {`Kokonaispaino: ${
                                                    assetInfo?.properties[
                                                        "trailer_weight"
                                                    ] || "-"
                                                }`}
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                component="div"
                                                className="fw-bold mb-1"
                                            >
                                                {`Korkeus: ${
                                                    assetInfo?.properties[
                                                        "trailer_height"
                                                    ] || "-"
                                                }`}
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                component="div"
                                                className="fw-bold mb-1"
                                            >
                                                {`Pituus: ${
                                                    assetInfo?.properties[
                                                        "trailer_length"
                                                    ] || "-"
                                                }`}
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                component="div"
                                                className="fw-bold"
                                            >
                                                {`Rekisteritunnus: ${
                                                    assetInfo?.properties[
                                                        "license_number"
                                                    ] || "-"
                                                }`}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Item>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
                <Box sx={{ pt: 8, pb: 8, background: "#f4f4f4" }}>
                    <Container component="main">
                        <div className="max-w-1000">
                            <Grid container spacing={{ xs: 2, md: 3, lg: 10 }}>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h5" className="mb-3">
                                        Vahva tunnistautuminen
                                    </Typography>
                                    <Typography component="p" className="mb-3">
                                        HUOM! Perävaunua voi lainata vain
                                        toimivilla korkeakoulujen
                                        sähköpostiosoitteilla (esim.
                                        metropolia.fi). Sähköpostiosoitteena
                                        käyvät Helsingin seudun ja Lappeenrannan
                                        korkeakoulujen osoitteet. Saat
                                        sähköpostiisi varausvahvistuksen ja muut
                                        tiedot noutoa varten..
                                    </Typography>
                                    <Typography component="p">
                                        Mikäli järjestelmä ei tunnista
                                        korkeakoulusi sähköpostiosoitetta, laita
                                        meiliä osoitteeseen
                                        info(at)fiksuveto.fi. Perävaunun
                                        varaaminen ei maksa mitään, vaikka
                                        tunnistautumisessa käytetään
                                        pankkitunnuksia.
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={5}
                                    className="contact-form asset-resv-contact-form"
                                >
                                    <Typography variant="h7" className="mb-3">
                                        Syötä asiakastiedot
                                    </Typography>
                                    <SmartForm onSubmit={onSubmit}>
                                        <InputFieldWithValidation
                                            label="Puhelinnumero *"
                                            type="tel"
                                            pattern="[+]{1}[0-9]{11,14}"
                                            className="mb-3"
                                            placeholderText="+3581122334455"
                                            required={true}
                                            name="phone"
                                            id="mob"
                                            onChange={(e) => handlInput(e)}
                                            style={{ fontSize: 1 }}
                                        />
                                        <InputFieldWithValidation
                                            name="Email"
                                            label="Sähköposti *"
                                            type="email"
                                            className="mb-4"
                                            id="emailid"
                                            placeholderText="matti.meikäläinen@mail.com"
                                            onChange={(e) => handlInput(e)}
                                            required={true}
                                        />
                                        <div className="mb-4">
                                            <label className="checkbox-container">
                                                {" "}
                                                Olen lukenut ja hyväksynyt{" "}
                                                <a
                                                    href="https://fiksuveto.fi/kayttoehdotcarry/"
                                                    target="_blank"
                                                >
                                                    käyttöehdot.
                                                </a>
                                                .
                                                <input
                                                    type="checkbox"
                                                    name="checkbox"
                                                    onClick={(e) =>
                                                        handleCheck(e)
                                                    }
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>

                                        {user &&
                                            user?.phone?.length > 0 &&
                                            user?.Email?.length > 0 && (
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="large"
                                                    className="btn-custom-primary"
                                                    type="submit"
                                                    disabled={!user?.checkbox}
                                                >
                                                    Vahvista Varaus
                                                    Tunnistautumalla
                                                </Button>
                                            )}
                                    </SmartForm>
                                </Grid>
                            </Grid>
                        </div>
                    </Container>
                </Box>
            </main>
            <Box sx={{ background: "rgb(0 112 173)" }} component="footer">
                <Typography variant="h6" component="p" align="center">
                    Powered by Fiksuveto Oy
                </Typography>
            </Box>
        </ThemeProvider>
    );
};
export default AssetReservation;
