const AUTH_REDIRECT_URL = "https://carry.honkio.net/authorized";
const URI_REDIRECT_URL = `redirect_uri=https%3A%2F%2Fcarry.honkio.net%2Fauthorized`;

// Oauth url in order to authenticate
export const OAUTH_TOKEN_URL =
    process.env.REACT_APP_OAUTH_TOKEN_URL || "https://oauth.honkio.com/token";
export const OAUTH_REDIRECT_URL =
    process.env.REACT_APP_OAUTH_REDIRECT_URL || AUTH_REDIRECT_URL;

export const OAUTH_CLIENT_ID =
    process.env.REACT_APP_OAUTH_CLIENT_ID ||
    "kgvtFuka7x2kvhne66zkjH7dJdEp6iRG7kohaiki"; // local

export const OAUTH_CLIENT_SECRET =
    process.env.REACT_APP_OAUTH_CLIENT_SECRET ||
    "QznXtq7ImAIJ8kP6u9UeHMhsJPEoBS2Xqhg2K6yD"; // local

export const OAUTH_URL =
    process.env.REACT_APP_OAUTH_URL ||
    [
        `https://oauth.honkio.com/authorize?platform=web`,
        `scope=email`,
        `response_type=code`,
        `client_id=${OAUTH_CLIENT_ID}`,
        URI_REDIRECT_URL,
    ].join("&");

export const OAUTH_REVOKE_TOKEN_URL =
    process.env.REACT_APP_OAUTH_REVOKE_TOKEN_URL ||
    "https://oauth.honkio.com/revoke";

export const REGISTRATION_URL = `https://oauth.honkio.com/register/?platform=web&response_type=code&client_id=${OAUTH_CLIENT_ID}&redirect_uri=${AUTH_REDIRECT_URL}&scope=email`;

export const MERCHANT_REGISTER_URL = `https://oauth.honkio.com/register/company/?platform=web&response_type=code&client_id=${OAUTH_CLIENT_ID}&redirect_uri=${AUTH_REDIRECT_URL}&scope=email`;

// Internal API URL (Should be replaced in production)
export const INTERNAL_API_URL = "https://rental-backend.honkio.com"; // replace in prod

export const GOOGLE_API_KEY = "AIzaSyAK5T79Q9FhqPuYZ4cHl8rxFOX4Ck9KDHY";

// Main API URL
export const GOOGLE_ANALYTICS_ID = "";

export const BACKEND_URL =
    process.env.REACT_APP_BACKEND_URL || "https://api.honkio.com/";

export const CONSUMER_URL =
    process.env.REACT_APP_CONSUMER_URL || "https://consumer.2.honkio-dev.com/";

export const UPLOAD_URL =
    process.env.REACT_APP_CONSUMER_URL ||
    "https://media1.honkio.com/rental_dev/upload"; // fixme ??

// Authentication redirect path
export const REDIRECT_URL = "/landing";
export const APP_PACKAGE = "fiksuveto";
export const APPLICATION_ID = "6221dafa07a82cc26c04cf74";
export const MERCHANT_ID = "630723c7176dcb5cd7973068";
export const MERCHANT_API_KEY = "mKGFu3YS0KMCEgqwrEBzxkZWtb2cBzyMz7YjUTOV";
export const MERCHANT_NAME = "Fiksuveto for Gap gemini";
export const APPLICATION_NAME = "Fiksuveto";

export const SHOW_LOGIN_BUTTON = false;

export const ORDER_MODEL_RESERVE_BY_CUSTOMER = "gap_gemini_rent";
export const ORDER_MODEL_RESERVE_BY_OPERATOR = "reserved_behalf_of_customer";
export const ORDER_MODEL_MAINTENANCE = "maintenance";

export const ALLOWED_DOC_EXTENSIONS = [
    "doc",
    "docx",
    "xls",
    "xlsx",
    "pdf",
    "rtf",
    "html",
    "djvu",
    "ifc",
    "txt",
];

export const ALLOWED_EMAIL_DOMAINS = [
    "helsinki.fi",
    "aalto.fi",
    "metropolia.fi",
    "laurea.fi",
    "haaga-helia.fi",
    "stadia.fi",
    "mil.fi",
    "hanken.fi",
    "uniarts.fi",
    "arcada.fi",
    "diak.fi",
    "humak.fi",
    "hoas.fi",
    "capgemini.com",
    "sogeti.com",
    "enterventures.fi",
    "honkio.com",
    "turkuamk.fi",
    "tuni.fi",
    "utu.fi",
    "jamk.fi",
    "xamk.fi",
    "samk.fi",
    "jyu.fi",
    "student.lut.fi",
    "student.lab.fi",
    "student.uwasa.fi",
    "myy.haaga-helia.fi",
    "student.jyu.fi",
    "student.laurea.fi",
    "opp.eduvantaa.fi",
    "kamk.fi",
    "edu.pelastusopisto.fi",
    "hamk.fi",
    "student.diak.fi",
    "student.hanken.fi",

];

export const INCORRECT_EMAIL_DOMAIN_REDIRECT_URL =
    "https://fiksuveto.fi/vikailmoitus-sahkopostidomain/";

export const PAYMENT_THANK_YOU_URL = `https://carry.honkio.net/thankYou`;
export const PAYMENT_SUCCESS_URL =
    "https://tunnistus-pp.telia.fi/uas/authn/4efc24cf-03b1-4f9b-8c41-330607b86629/view?entityID=7040718c-c38d-4737-8940-53e45c14733a&locale=fi";
export const PAYMENT_FAIL_URL = "https://oauth.honkio.com/telia/response";
