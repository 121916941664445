// const dotenv = require('dotenv');
// dotenv.config();

const AUTH_REDIRECT_URL = "https://carry.honkio.net/authorized";
// const AUTH_REDIRECT_URL = "http://localhost:3000/authorized";

// Oauth url in order to authenticate
export const OAUTH_TOKEN_URL =
    process.env.REACT_APP_OAUTH_TOKEN_URL || "https://oauth.honkio.com/token";
export const OAUTH_REDIRECT_URL =
    process.env.REACT_APP_OAUTH_REDIRECT_URL || AUTH_REDIRECT_URL;

export const OAUTH_CLIENT_ID =
    process.env.REACT_APP_OAUTH_CLIENT_ID ||
    "kgvtFuka7x2kvhne66zkjH7dJdEp6iRG7kohaiki"; // local
export const OAUTH_CLIENT_SECRET =
    process.env.REACT_APP_OAUTH_CLIENT_SECRET ||
    "QznXtq7ImAIJ8kP6u9UeHMhsJPEoBS2Xqhg2K6yD "; // local
export const OAUTH_URL =
    process.env.REACT_APP_OAUTH_URL ||
    [
        `https://oauth.honkio.com/authorize?platform=web`,
        `scope=email`,
        `response_type=code`,
        `client_id=kgvtFuka7x2kvhne66zkjH7dJdEp6iRG7kohaiki`,
        `redirect_uri=https%3A%2F%2Fcarry.honkio.net%2Fauthorized`,
    ].join("&");
export const REGISTRATION_URL = `https://oauth.honkio.com/register/?platform=web&response_type=code&client_id=kgvtFuka7x2kvhne66zkjH7dJdEp6iRG7kohaiki&redirect_uri=${AUTH_REDIRECT_URL}&scope=email`;
export const MERCHANT_REGISTER_URL = `https://oauth.honkio.com/register/company/?platform=web&response_type=code&client_id=kgvtFuka7x2kvhne66zkjH7dJdEp6iRG7kohaiki&redirect_uri=${AUTH_REDIRECT_URL}&scope=email`;
// Internal API URL (Should be replaced in production)
export const INTERNAL_API_URL = "https://rental-backend.honkio.com"; // replace in prod
export const GOOGLE_API_KEY = "AIzaSyAK5T79Q9FhqPuYZ4cHl8rxFOX4Ck9KDHY";
// Main API URL
export const GOOGLE_ANALYTICS_ID = "";
export const BACKEND_URL =
    process.env.REACT_APP_BACKEND_URL || "https://api.honkio.com/";
export const CONSUMER_URL =
    process.env.REACT_APP_CONSUMER_URL || "https://consumer.2.honkio-dev.com/";
export const UPLOAD_URL =
    process.env.REACT_APP_CONSUMER_URL ||
    "https://media1.honkio.com/rental_dev/upload"; // fixme ??

// Authentication redirect path
export const REDIRECT_URL = "/landing";
export const APP_PACKAGE = "fiksuveto";
export const APPLICATION_ID = "6221dafa07a82cc26c04cf74";
export const MERCHANT_ID = "630723c7176dcb5cd7973068";
export const MERCHANT_NAME = "Fiksuveto";
export const APPLICATION_NAME = "Fiksuveto";

export const SHOW_LOGIN_BUTTON = false;
